<template>
  <div class="content">
    <button v-if="isCart" class="allclearbn" @click="clearAllCart">
      カートの内容を削除する
    </button>

    <plugin_cartFlow flow="2" />
    <div class="steps" v-if="loaded">
      <div class="steptitle">ステップ2：詳細内容の選択</div>
      <div class="stepcontent">
        <div v-for="i in unit" :key="i" :id="'item' + i" :ref="'item' + i">
          <p class="order_unittitle" v-if="unit != 1">
            {{ i }}枚目の内容を選択
          </p>
          <p class="order_unittitle" v-else>内容をご記入ください</p>

          <p class="order_unitdetail" v-if="unit != 1">
            {{ i }}枚目<!--【{{tagged_values[i-1].letter_type}}】-->【{{
              tagged_values[i - 1].letter_callname
            }}】 【{{ tagged_values[i - 1].letter_target }}】 【{{
              tagged_values[i - 1].letter_lang
            }}】 の内容をご記入ください
          </p>
          <div>
            <plugin_itemoptions
              :options_filtered="options_filtered"
              :cartIndex="i - 1"
              :posterrors="errors_global"
            />

            <div class="more_separator">
              ご記入有難うございます。お手紙のみのご注文の場合は以上で記入は完了です。<br />
              次のご依頼主様情報の入力にお進みください。
            </div>

            <div class="itemoptions">
              <dl>
                <dt><p class="title">オプションを追加する</p></dt>
                <dd>
                  <p class="imgline">
                    <img src="../../assets/images/optioninfo.jpg" alt="" />
                  </p>
                  <p class="imginfo1">
                    ※送料は地域によって異なります。<a
                      target="_blank"
                      href="/preview/images/deliverprice.png"
                      >＞地域別送料一覧表</a
                    >
                  </p>
                  <p class="imginfo2">
                    ご希望の方は、以下のクリスマスオプションサービスをご選択いただけます。<br />
                    オプションは各お手紙ごとのご選択となります。
                  </p>
                </dd>
              </dl>
            </div>

            <div
              class="steptitle2"
              :id="'item' + i + '-options'"
              :ref="'item' + i + '-options'"
            >
              オプション選択
            </div>

            <div class="itemoptionmiddle">
              <p class="info">
                オプション不要の方はこのまま下にスクロールして【次へ】ボタンを押してください。
              </p>
              <p class="info">希望商品にチェックを入れてください</p>
              <ul class="selector" :id="'deliver1-' + i">
                <li class="current">
                  <p class="subtitle">▼ 送料無料範囲商品</p>
                  <p class="subinfo">
                    クロネコゆうパケット便*にて<br />お手紙と一緒にお届け
                  </p>
                </li>
                <li v-scroll-to="'#deliver2-' + i">
                  <p class="subtitle">▼ 宅急便対応商品</p>
                  <p class="subinfo">
                    宅急便（別途送料）にて<br />お手紙とお届け
                  </p>
                </li>
              </ul>
              <div class="info">
                *クロネコゆうパケット便はヤマト運輸と日本郵政のお荷物をポストに投函するサービスです
              </div>
            </div>

            <div class="itemoptionmiddle2">
              <div class="title">■送料無料商品</div>
              <div class="info">
                <p class="subtitle">送料無料範囲について</p>
                <p class="desc">
                  お手紙と一緒にクロネコゆうパケットに入る範囲のご注文が送料無料になります。商品は色付きの宅配袋にお手紙と同梱してお届けします。<br />
                  ■小物商品：<strong class="red">3点</strong>まで
                  ■大型商品：<strong class="red">1点</strong>まで<br />
                  ■大型商品と小物商品の場合：<strong class="red"
                    >大型商品1点+小物商品1点</strong
                  >まで<br />
                  上記以上の点数をご注文の場合、<strong>宅急便対応（別途送料・到着日指定可能）</strong>となりますのでご注意ください。
                </p>
              </div>
            </div>

            <div class="itemoptions_items">
              <plugin_itemoptions
                :options_filtered="options_filtered2"
                :cartIndex="i - 1"
                :posterrors="errors_global"
              />
            </div>

            <div class="itemoptionmiddle">
              <ul class="selector">
                <li v-scroll-to="'#deliver1-' + i" :id="'deliver2-' + i">
                  <p class="subtitle">▼ 送料無料範囲商品</p>
                  <p class="subinfo">
                    クロネコゆうパケット便*にて<br />お手紙と一緒にお届け
                  </p>
                </li>
                <li class="current">
                  <p class="subtitle">▼ 宅急便対応商品</p>
                  <p class="subinfo">
                    宅急便（別途送料）にて<br />お手紙とお届け
                  </p>
                </li>
              </ul>
              <div class="info">
                *クロネコゆうパケット便はヤマト運輸と日本郵政のお荷物をポストに投函するサービスです
              </div>
            </div>

            <div class="itemoptionmiddle2 blue">
              <div class="title">■宅急便対応商品</div>
              <div class="info">
                <p class="subtitle">宅急便対応商品について</p>
                <p class="desc">
                  宅急便でお手紙と一緒に、ご購入いただいたオプション品を同梱してお届け致します。到着日は当社指定期日よりご選択可能です。
                </p>
              </div>
            </div>

            <div class="itemoptions_items">
              <plugin_itemoptions
                :options_filtered="options_filtered3"
                :cartIndex="i - 1"
                :posterrors="errors_global"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footbns">
      <a class="bn_back" @click.prevent="back">前へもどる</a>
      <a class="bn_next" @click.prevent="submit">次へ進む</a>
    </div>

    <dialogalert
      v-model="visible_error"
      :title="error_title"
      :info="error_info"
      button_ok="OK"
    />

    <fullloader :loading="fullloading" />
  </div>
</template>

<script>
//import { mapState, mapMutations, mapActions } from "vuex";
import plugin_itemoptions from "@/components/itemoption";
export default {
  components: { plugin_itemoptions },
  data: function () {
    return {
      typetag: "option2",
      values: [],
      tagged_values: [],
      options_filtered: [],
      options_filtered2: [],
      options_filtered3: [],
    };
  },
  created: function () {
    //console.log(jQuery,$,"111111111111111111111111------------------------------------------------------------------------")
    $(document).on("keydown", ".letter_msg textarea", function (e) {
      if (e.key == "Enter") {
        return false;
      }
    });
    $(document).on("input", ".letter_msg textarea", function (e) {
      if (!$(this).next(".maxbox").length) {
        $(this).after('<div class="maxbox">maxxxx</div>');
      }
      if ($(this).val().length > 60) {
        //$(this).val($(this).val().substr(0, 60));
      }
      setTimeout(
        function () {
          $(this)
            .next(".maxbox")
            .html(
              "現在" +
                $(this).val().length +
                "文字／最大60文字" +
                ($(this).val().length > 60 ? " ※オーバーしています" : "")
            );
        }.bind(this),
        100
      );

      //
    });
    this.fullloading = true;
    this.$parent.middle = 2;
    this.loaded_countmax = 1;
    //まず商品定義を読み込む
    //alert(this.$route.params.label)
    this.get_itemoption(this.$route.params.label).then(() => {
      this.options_filtered = this.options_filter("option2");
      this.options_filtered2 = this.options_filter("deliver1");
      this.options_filtered3 = this.options_filter("deliver2");
      //console.log("options_filtered",this.options_filtered);
      //this.option_available=true;
      this.values = this.record.fields.items.subfields;
      var tagged_values = [];
      for (var i = 0; i < this.values.length; i++) {
        console.log("aa", this.get_option_value(i));
        tagged_values.push(this.get_tagged_values(this.get_option_value(i)));
      }
      this.tagged_values = tagged_values;
      //console.log("tagged_values",tagged_values[0].letter_callname);
      this.loaded_countup();
      this.hashScroll();
    });
  },
  computed: {
    //...mapState(["options"]),
    label: function () {
      return this.$route.params.label;
    },
    ID: function () {
      return this.$route.params.ID;
    },
  },
  methods: {
    get_option_value(index) {
      if (this.values[index].options.value) {
        return JSON.parse(this.values[index].options.value);
      } else {
        return [];
      }
    },

    back: function () {
      this.scrollToTop();
      if (this.ID) {
        this.$router.push("/item/" + this.label + "/update/" + this.ID);
      } else {
        this.$router.push("/item/" + this.label + "/");
      }
    },
    submit: function () {
      this.scrollToTop();
      this.fullloading = true;
      var record = this.getSession("record");
      //ログイン済みの場合はトークン、非会員の場合はuserを「-1」にする。
      var token = this.get_localStorage("token");
      if (!token) record.user = "-1";
      this.fetch("order_submit", {
        token: token,
        record: record,
        mode: "check",
      }).then((data) => {
        console.log(data);
        if (typeof data.record != "undefined")
          this.$set(
            this.record,
            "fields",
            Object.assign(this.record.fields, data.record.fields)
          );
        this.errors_global = {};
        if (typeof data.error != "undefined") {
          this.errors_global = data.error;
          console.log(data.error);
          this.error_info = "不備の項目があります。<br>";
          var filtered_ids = [];

          let options_filtered = this.options_filter(
            "option2,deliver1,deliver2"
          );

          for (let i = 0; i < options_filtered.length; i++) {
            filtered_ids.push(options_filtered[i].ID);
          }
          console.log("filtered_idsfiltered_idsfiltered_ids", filtered_ids);
          for (let i = 0; i < data.error.length; i++) {
            let optionID = data.error[i].id.match(
              /^field_items-([0-9]+)-option([0-9]+)$/
            );
            if (optionID !== null) console.log(filtered_ids, optionID[2]);
            if (optionID !== null && filtered_ids.indexOf(optionID[2]) != -1) {
              this.error_info +=
                "・ " +
                (optionID[1] - 0 + 1) +
                "枚目の" +
                data.error[i].msg +
                "<br>";
              this.visible_error = true;
            }
          }
        }

        this.fullloading = false;

        if (!this.visible_error) {
          if (this.$route.query.rd == "back") {
            this.$router.back();
          } else {
            if (this.ID) {
              this.$router.push(
                "/item/" + this.label + "/preview/update/" + this.ID
              );
            } else {
              this.$router.push("/item/" + this.label + "/preview");
            }
          }
        }
      });
    },
    //...mapMutations([]),
    //...mapActions([]),
  },
};
</script>

<style scoped lang="scss">
$tab: 680px; // タブレット
@mixin sp {
  @media (max-width: ($tab)) {
    @content;
  }
}

.order_unittitle {
  margin-top: 2em;
}
.steps {
  margin-top: 2em;
}
.content {
  color: #000;
}
h1 {
  margin-top: 1em;
  color: #383838;
  font-size: 2em;
}
h2 {
  motion-path: 1.5em;
}
h4 {
  background: rgb(253, 213, 125);
  padding: 1.5em 2em;
}

.steptitle2 {
  font-size: 1.8em;
  line-height: 1;
  background: #fff;
  display: block;
  padding: 0.3em 0.3em;
  margin: 1.5em 0.2em 0.3em 0.2em;
  border-bottom: solid 0.2em #d6d6d6;
  &::before {
    content: "▶";
    color: #cb3024;
  }
}

.imgline {
  text-align: center;
  img {
    max-width: 90%;
  }
}
.imginfo1 {
  margin-top: 1em;
  font-size: 0.8em;
  @include sp {
    font-size: 0.65em;
  }
  padding: 0 1em;
}
.imginfo2 {
  margin-top: 1em;
  font-size: 1em;
  @include sp {
    font-size: 0.7em;
  }
  padding: 0 1em;
}

.itemoptionmiddle {
  padding: 0.4em;
  .info {
    margin-top: 0.3em;
    font-size: 1em;
    @include sp {
      font-size: 0.7em;
    }
    padding: 0 0.2em;
  }
  .selector {
    list-style: none;
    display: flex;
    flex-direction: row;
    box-shadow: 0.2em.2em 0.2em;
    margin: 1em 0;
    &::before {
      display: none;
    }
    li {
      padding: 0.3em;
      text-align: center;
      width: 100%;
      background: #1f376b;
      color: rgb(225, 225, 226);
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      &.current {
        color: #d72e23;
        cursor: default;
        background: #fff;
        position: relative;
        &:hover {
          opacity: 1;
        }
        &::before {
          content: "";
          border: solid 20px #fff;
          border-left-color: transparent;
          border-bottom-width: 0;
          border-right-color: transparent;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -19px;
          filter: drop-shadow(0em 0.25em 0.05em #000);
        }
      }
      .subtitle {
        font-size: 1.2em;
        font-weight: bold;
        @include sp {
          font-size: 4.5vw;
        }
      }
      .subinfo {
        font-size: 1.5vw;
        br {
          display: none;
        }
        @include sp {
          font-size: 3.5vw;
          display: inherit;
        }
      }
    }
  }
}

.itemoptionmiddle2 {
  padding: 0 0.4em;
  .title {
    background: #d72e23;
    padding: 0.5em 1em;
    color: #fff;
  }
  &.blue {
    .title {
      background: #1f376b;
    }
    .subtitle {
      color: #1f376b;
    }
    .info {
      background: #d0e8fc;
    }
  }
  .info {
    padding: 0.9em;
    background: #ffe2dc;
  }
  .subtitle {
    padding: 0.2em 0 0.8em 0;
    text-align: center;
    color: #d72e23;
  }
  .desc {
    color: #454552;
    font-size: 0.8em;
    @include sp {
      font-size: 1em;
    }
    strong.red {
      color: #d72e23;
    }
  }
}
.itemoptions_items {
  &::v-deep ul {
    padding: 1em;
    li {
      margin-top: 2em;
      border: solid 0.3em #d72e23;
      dl {
        padding: 1em;
        flex-direction: column;
        dt {
          width: 100%;
          border-bottom: solid 0.2em #d72e23;
          background: none !important;
          margin-bottom: 0;
          padding: 0em !important;
          padding-bottom: 0.3em !important;
          border-right: none !important;
          p {
            display: block;
            text-align: center;
            background: transparent;
            width: 100%;
            border-bottom: solid 0.3em #d72e23;
            margin-bottom: 0;
            padding: 0 !important;
            padding-bottom: 0.5em !important;
          }
        }
        dd {
          padding: 0em;
          padding-top: 1em;
          flex-wrap: wrap;
          justify-content: space-between;
          @include sp {
            display: block;
          }
          .unit {
            display: block;
            select {
              margin-top: 0.3em;
              font-size: 1.3em;
            }
          }
          .stockview {
            margin-left: 0.5em;
            font-weight: normal;
            font-size: 0.9em;
          }
          .stock_sub {
            display: block;
            font-weight: normal;
            font-size: 0.7em;
            margin-top: 0.3em;
          }
          .price {
            margin-top: 0.3em;
            font-size: 1.2em;
            color: #d72e23;
            font-weight: bold;
            span {
              font-size: 0.7em;
            }
          }
          .option_description {
            width: 60%;
            margin-left: 40%;
            @include sp {
              width: 100%;
              margin-left: 0;
            }
          }
          .option_childs {
            width: 60%;
            margin-left: 40%;
            @include sp {
              width: 100%;
              margin-left: 0;
            }
          }
          .option_description2 {
            width: 60%;
            margin-left: 40%;
            @include sp {
              width: 100%;
              margin-left: 0;
            }
          }
          .img {
            width: 33%;
            float: left;
            @include sp {
              margin: 0 auto;
              width: 90%;
              float: none;
            }
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.itemoptions p.title {
  font-size: 1.5em;
  text-align: center;
  margin: 1em 0 0.5em;
}
</style>